import * as React from 'react';
import ReactDOM from 'react-dom';
// import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import {Router} from "react-router";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

ReactDOM.render(
  <React.Fragment>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    {/*<CssBaseline />*/}
      <Router history={history}>
          <App />
      </Router>
  </React.Fragment>,
  document.getElementById('root'),
);
