import MuiButton from './MuiButton';
import MuiButtonBase from "./MuiButtonBase";
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiPaper from './MuiPaper';
import MuiAppBar from './MuiAppBar';
import MuiDrawer from './MuiDrawer';
import MuiToolBar from './MuiToolBar';

export default {
    MuiAppBar,
    MuiButton,
    MuiButtonBase,
    MuiOutlinedInput,
    MuiFormControlLabel,
    MuiPaper,
    MuiToolBar,
    MuiDrawer,
};
