export default {
  translation: {
    'shared.french': 'French',
    'shared.english': 'English',
    navigation_config_apps: 'Apps',
    navigation_config_dashboard: 'Dashboard',
    navigation_config_done: 'Vorgange',
    navigation_config_calendar: 'Fristen',
    navigation_config_documents: 'Documents',
    navigation_config_process: 'Prozessansicht',
  }
};
