import React from 'react';

function Logo(props: any) {
  return (
    <div  {...props} style={{
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "center"
    }}>
      <img alt="KIMPRO" src="/static/images/KIMPRO.svg" style={{
        maxHeight: 40,
        margin: "0px 4px"
      }}/>
      <img alt="seybold" src="/static/images/Seybold.png" style={{
        filter: "brightness(0%)",
        maxHeight: 20,
        margin: "0px 4px"
      }}/>
    </div>
  );
}

export default Logo;
