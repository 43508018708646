export default {
  translation: {
    'shared.french': 'Francais',
    'shared.english': 'Anglais',
    'loading_map_text': 'Chargement de la carte',
    "field_require": "Champ requis",
    "login_page_action": "Se connecter",
    "login_page_title": "Connexion",
    "login_form_phone": "Email",
    'shared.internet_connexion_error': 'Vérifiez votre connexion internet!',
    "login_form_password": "Mot de passe",
    "login_form_require": "Champ requis",
    "unable_to_disconnect": "Impossible de se deconnecter",
    "login_form_type_error": "Veuillez entrer un numéro valide",
    "login_page_subtitle": "Veuillez remplir le formulaire ci-dessous",
    "login_page_forgot_password": "Mot de passe oublier",
    "field_type_number_error": "Veuillez saisir un nombre",
    "field_type_number_speed_error": "La vitesse minimal doit être supérieur a 20",
    auto_complete_loading: 'Chargement',
    auto_complete_empty: 'Auccun résultat trouver',
    auto_complete_keyword_empty: 'Veuillez saisir un mot cléf',
    navigation_config_dashboard: 'Dashboard',
    email_form_invalid: 'Veuillez saisir une adresse email valide',
    topbar_profile_logout: 'Déconnexion',
    topbar_profile_profil: 'Profil',
    navigation_config_collection: 'Collections',
    navigation_config_single_type: 'Types uniques',
    navigation_config_config: 'Configurations',
    navigation_config_type_content: 'Types de contenus',
    navigation_config_media: 'Médiathèques',
    navigation_config_admin: 'Administrations',
    navigation_config_admin_role: 'Rôle',
    navigation_config_admin_user: 'Utilisateurs',
    navigation_config_apps: 'Apps',
    navigation_config_done: 'Vorgange',
    navigation_config_calendar: 'Fristen',
    navigation_config_documents: 'Documents',
    navigation_config_process: 'Prozessansicht',
    find_location: 'Rechercher un emplacement...',
    review_dialog_action_cancel: 'Annuler',
    create_attribute_action: 'Annuler',
    review_dialog_action_next: 'Suivant',
    review_dialog_action_preview: 'Précédent',
    create_attribute_update: 'Mettre à jour',
    create_attribute_add: 'Ajouter',
    create_attribute_title: 'Titre',
    create_attribute_label: 'Libelé',
    create_attribute_config: 'Configuration',
    create_attribute_description: 'Description',
    create_attribute_required: 'Champ obligatoire',
    create_attribute_default: 'Valeur par défault',
    create_attribute_unique: 'Champ unique',
    create_attribute_visible: 'Champ visible',
    create_attribute_enumeration_label: 'Énumération',
    create_attribute_enumeration_placeholder: 'Séparer les valeurs par un retour a la ligne. Eg.\nMaison\nVoiture\nMoto',
    create_attribute_add_title: 'Attributs',
    field_number_error: 'Veuillez saisir un nombre',
    field_email_error: 'Veuillez saisir une adresse mail valide',
    create_attribute_type: 'Selectionnez un type de champ pour votre collection',
    create_attribute_localisation: 'Activer la localisation',
    create_attribute_localisation_sub: 'Le champs pourra avoir une valeur par langues',
    create_attribute_unique_sub: 'Vous ne pourrez pas créer une entrée s\'il existe un champ similaire',
    create_attribute_visible_sub: 'Afficher le champ lord du listing',
    create_attribute_required_sub: 'Vous ne pourrez pas créer une entrée si ce champ est vide',
    settings_add_action: 'Ajouter',
    default_boolean_sub: 'Cocher pour true(vrai) et décocher pour false(faux)',
    create_attribute_TEXT: 'Texte',
    create_attribute_LONG_TEXT: 'Long Texte',
    create_attribute_RICH_TEXT: 'Texte Enrichi',
    create_attribute_RICH_TEXT_sub: 'Texte pouvant être formater',
    create_attribute_EMAIL: 'Email',
    create_attribute_EMAIL_sub: 'Validation pour adresse mail',
    create_attribute_NUMBER: 'Nombre',
    create_attribute_NUMBER_sub: 'Nombre(entier, flottants, décimaux)',
    create_attribute_DATE: 'Date',
    create_attribute_DATE_sub: 'Choisir un jour',
    create_attribute_DATE_TIME: 'Date et Heure',
    create_attribute_DATE_TIME_sub: 'Choisir un jour et heure',
    create_attribute_TIME: 'Heure',
    create_attribute_TIME_sub: 'Choisir une Heure',
    create_attribute_BOOLEAN: 'Booléen',
    create_attribute_BOOLEAN_sub: 'Oui ou non, vrai ou faux, 0 ou 1',
    create_attribute_ENUMERATION: 'Enumeration',
    create_attribute_ENUMERATION_sub: 'Liste de valeurs',
    create_attribute_MEDIA: 'Media',
    empty_collection_title: 'Aucune collection trouver',
    empty_collection_subtitle: 'Veuillez cliquer sur le button ci-dessous pour ajouter une collection',
    empty_collection_button: 'Ajouter',
    create_attribute_MEDIA_sub: 'Fichiers images, vidéos, PDF, etc',
    create_attribute_TEXT_sub: 'Texte de moins de 255 caractères',
    create_attribute_LONG_TEXT_sub: 'Texte de grande taille',
  }
};
