export default {
    styleOverrides: {
        root: {
            fontWeight: 500,
            minHeight: 48,
            boxShadow: 'none!important',
            padding: '12px 24px',
            color: "#FFFFFF"
        },

    }
};
