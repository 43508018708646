import {combineReducers, Reducer} from 'redux';
//cli_import_section
import CollectionReducer from './reducers/CollectionReducer';
import {ApplicationState} from './types';
import InternationalisationReducer from "./reducers/InternationalisationReducer";
import AccountReducer from "./reducers/AccountReducer";
import ClassificationReducer from "./reducers/ClassificationReducer";
const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    //cli_reducer_section
	collection : CollectionReducer,
	account: AccountReducer,
	i18n: InternationalisationReducer,
	classification: ClassificationReducer,
});

export default rootReducer;
