import React, {useState} from 'react';
import {NavBar, TopBar} from './components';
import {ErrorHandler} from 'components';
import styles from './Application.module.css';
import { Theme} from "@mui/material";
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => ({
    content:{
      background: "rgba(250, 250, 250, 1)",
    },
    root:{
      background: "rgba(250, 250, 250, 1)",
    },
}));

const Application = (props: any) => {
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const classes = useStyles();

  return (
    <div className={styles.root}>
      <ErrorHandler>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </ErrorHandler>
      <ErrorHandler>
        <TopBar className={styles.topBar} onMobileNavOpen={() => setMobileNavOpen(true)} />
      </ErrorHandler>
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <ErrorHandler>{children}</ErrorHandler>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
