import {
    AddClassificationError,
    AddClassificationRequest,
    AddClassificationSuccess,
    ADD_CLASSIFICATION_FAILURE,
    ADD_CLASSIFICATION_REQUEST,
    ADD_CLASSIFICATION_SUCCESS,
    DeleteFileError,
    DeleteFileRequest,
    DeleteFileSuccess,
    DELETE_FILE_FAILURE,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    File,
    GetAllClassificationError,
    GetAllClassificationRequest,
    GetAllClassificationSuccess,
    GetClassificationError,
    GetClassificationRequest,
    GetClassificationSuccess,
    GET_ALL_CLASSIFICATION_FAILURE,
    GET_ALL_CLASSIFICATION_REQUEST,
    GET_ALL_CLASSIFICATION_SUCCESS,
    GET_CLASSIFICATION_FAILURE,
    GET_CLASSIFICATION_REQUEST,
    GET_CLASSIFICATION_SUCCESS,
    PreviewFileError,
    PreviewFileRequest,
    PreviewFileSuccess,
    PREVIEW_FILE_FAILURE,
    PREVIEW_FILE_REQUEST,
    PREVIEW_FILE_SUCCESS,
    StatusError,
    StatusRequest,
    StatusSuccess,
    STATUS_FAILURE,
    STATUS_REQUEST,
    STATUS_SUCCESS,
    UploadFileError,
    UploadFileRequest,
    UploadFileSuccess,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS
} from 'store/types';


/** Get All classifications */
export const getAllClassificationRequest = (): GetAllClassificationRequest => ({
    type: GET_ALL_CLASSIFICATION_REQUEST
});

export const getAllClassificationSuccess = (
    items: Array<File>
): GetAllClassificationSuccess => ({
    type: GET_ALL_CLASSIFICATION_SUCCESS,
    classifications: items,
    message: 'Success'
});

export const getAllClassificationError = (message: string): GetAllClassificationError => ({
    type: GET_ALL_CLASSIFICATION_FAILURE,
    message
});
/** Add a classification */
export const addClassificationRequest = (): AddClassificationRequest => ({
    type: ADD_CLASSIFICATION_REQUEST
});

export const addClassificationSuccess = (
    file: string
): AddClassificationSuccess => ({
    type: ADD_CLASSIFICATION_SUCCESS,
    message: 'Success',
    file: file
});

export const addClassificationError = (message: string): AddClassificationError => ({
    type: ADD_CLASSIFICATION_FAILURE,
    message
});
/** get a single classification */


export const getClassificationRequest = (): GetClassificationRequest => ({
    type: GET_CLASSIFICATION_REQUEST
});

export const getClassificationSuccess = (): GetClassificationSuccess => ({
    type: GET_CLASSIFICATION_SUCCESS,
    message: 'Success',
});

export const getClassificationError = (message: string): GetClassificationError => ({
    type: GET_CLASSIFICATION_FAILURE,
    message
});


/** delete a single classification */


export const deleteClassificationRequest = (): DeleteFileRequest => ({
    type: DELETE_FILE_REQUEST
});

export const deleteClassificationSuccess = (
    file: string
): DeleteFileSuccess => ({
    type: DELETE_FILE_SUCCESS,
    message: 'Success',
    file: file,
});

export const deleteClassificationError = (message: string): DeleteFileError => ({
    type: DELETE_FILE_FAILURE,
    message
});

/** upload file request */


export const uploadFileRequest = (): UploadFileRequest => ({
    type: UPLOAD_FILE_REQUEST
});

export const uploadFileSuccess = (
    message:string
): UploadFileSuccess => ({
    type: UPLOAD_FILE_SUCCESS,
    message,
});

export const uploadFileError = (message: string): UploadFileError => ({
    type: UPLOAD_FILE_FAILURE,
    message
});


/** upload file request */


export const previewFileRequest = (): PreviewFileRequest => ({
    type: PREVIEW_FILE_REQUEST
});

export const previewFileSuccess = (
    urls: Array<string>
): PreviewFileSuccess => ({
    type: PREVIEW_FILE_SUCCESS,
    message: 'Success',
    previewUrls: urls,
});

export const previewFileError = (message: string): PreviewFileError => ({
    type: PREVIEW_FILE_FAILURE,
    message
});

/** status file request */


export const statusRequest = (): StatusRequest => ({
    type: STATUS_REQUEST
});

export const statusSuccess = (): StatusSuccess => ({
    type: STATUS_SUCCESS,
    message: 'Success',
});

export const statusFileError = (message: string): StatusError => ({
    type: STATUS_FAILURE,
    message
});