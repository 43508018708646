export default {
    styleOverrides: {
        root: {
            background: "#FFFFFF",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.07)",
            color: "#000000",
        },

    }
};
