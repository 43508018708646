// import {Home as HomeIcon} from "@material-ui/icons";
import {Calendar, CheckSquare, Database, File, GitCommit, Grid, Home, User} from "react-feather";


export default [
  {
    subheader: '',
    items: [
      {
        title: 'Apps',
        href: '/app/home',
        /* icon: Database, */
        items: [
          {
            title: 'Dashboard',
            href: '/app/home',
            icon: Home,
          },
          {
            title: 'Vorgänge',
            href: '/app/vorgänge',
            icon: CheckSquare,
          },
          {
            title: 'Fristen',
            href: '/app/fristen',
            icon: Calendar,
          },
          {
            title: 'Dokumente',
            href: '/app/dokumente',
            icon: File,
          },
          {
            title: 'Prozessansicht',
            href: '/app/prozessansicht',
            icon: Grid,
          },
        ]
      },
      {
        title: 'Verwaltung',
        href: '/app/verwaltung',
        /* icon: Database, */
        items: [
          {
            title: 'Einstellungen',
            href: '/app/einstellungen',
            icon: GitCommit,
          },
          {
            title: 'Benutzer & Rechte',
            href: '/app/benutzer',
            icon: User,
          },
          {
            title: 'Vorlagen',
            href: '/app/vorlagen',
            icon: undefined,
          },
          
        ]
      },
      {
        title: '> Wechseln zu KIM',
        href: '/app/wechseln',
        /* icon: Database, */
        items: [
        ]
      },
    ]
  }
];
