import {Action} from 'redux';

export interface File {
    document_name: string,
    document_type: string,
    document_type_confidence: string,
    document_year: string,
    document_year_confidence: string,
    document_location: string,
    document_location_confidence: string,
    document_location_id: number,
    document_company: string,
    document_group: string,
}

export const GET_ALL_CLASSIFICATION_REQUEST = 'GET_ALL_CLASSIFICATION_REQUEST';
export const GET_ALL_CLASSIFICATION_SUCCESS = 'GET_ALL_CLASSIFICATION_SUCCESS';
export const GET_ALL_CLASSIFICATION_FAILURE = 'GET_ALL_CLASSIFICATION_FAILURE';

export const ADD_CLASSIFICATION_REQUEST = 'ADD_CLASSIFICATION_REQUEST';
export const ADD_CLASSIFICATION_SUCCESS = 'ADD_CLASSIFICATION_SUCCESS';
export const ADD_CLASSIFICATION_FAILURE = 'ADD_CLASSIFICATION_FAILURE';

export const GET_CLASSIFICATION_REQUEST = 'GET_CLASSIFICATION_REQUEST';
export const GET_CLASSIFICATION_SUCCESS = 'GET_CLASSIFICATION_SUCCESS';
export const GET_CLASSIFICATION_FAILURE = 'GET_CLASSIFICATION_FAILURE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const PREVIEW_FILE_REQUEST = "PREVIEW_FILE_REQUEST";
export const PREVIEW_FILE_SUCCESS = "PREVIEW_FILE_SUCCESS";
export const PREVIEW_FILE_FAILURE = "PREVIEW_FILE_FAILURE";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const STATUS_REQUEST = "STATUS_REQUEST";
export const STATUS_SUCCESS = "STATUS_SUCCESS";
export const STATUS_FAILURE = "STATUS_FAILURE";

export interface LoadingClassificationState{
    get_all_classification_loading: boolean;
    get_all_classification_success: boolean;
    get_all_classification_failed: boolean;
    get_all_classification_failed_message: string;

    add_classification_loading: boolean;
    add_classification_success: boolean;
    add_classification_failed: boolean;
    add_classification_failed_message: string;

    get_classification_loading: boolean;
    get_classification_success: boolean;
    get_classification_failed: boolean;
    get_classification_failed_message: string;

    upload_file_loading: boolean;
    upload_file_success: boolean;
    upload_file_failed: boolean;
    upload_file_failed_message: string;

    preview_file_loading: boolean;
    preview_file_success: boolean;
    preview_file_failed: boolean;
    preview_file_failed_message: string;

    delete_file_loading: boolean;
    delete_file_success: boolean;
    delete_file_failed: boolean;
    delete_file_failed_message: string;

    status_loading: boolean;
    status_success: boolean;
    status_failed: boolean;
    status_failed_message: string;
}

export interface ClassificationState{
    loading: LoadingClassificationState;
    file?: string;
    classifications: Array<File>;
    previewUrls:Array<string>
}

export interface GetAllClassificationRequest extends Action{
    type: typeof GET_ALL_CLASSIFICATION_REQUEST;
}

export interface GetAllClassificationSuccess extends Action{
    type: typeof GET_ALL_CLASSIFICATION_SUCCESS;
    classifications: Array<File>, 
    message: string;
}
export interface GetAllClassificationError extends Action{
    type: typeof GET_ALL_CLASSIFICATION_FAILURE;
    message: string;
}
export interface AddClassificationRequest extends Action{
    type: typeof ADD_CLASSIFICATION_REQUEST;
}
export interface AddClassificationSuccess extends Action{
    type: typeof ADD_CLASSIFICATION_SUCCESS;
    message: string;
    file?: string;
}
export interface AddClassificationError extends Action{
    type: typeof ADD_CLASSIFICATION_FAILURE;
    message: string;
}
export interface GetClassificationRequest extends Action{
    type: typeof GET_CLASSIFICATION_REQUEST;
}
export interface GetClassificationSuccess extends Action{
    type: typeof GET_CLASSIFICATION_SUCCESS;
    message: string;
}
export interface GetClassificationError extends Action{
    type: typeof GET_CLASSIFICATION_FAILURE;
    message: string;
}
export interface UploadFileRequest extends Action{
    type: typeof UPLOAD_FILE_REQUEST;
}

export interface UploadFileSuccess extends Action{
    type: typeof UPLOAD_FILE_SUCCESS;
    message: string;
}
export interface UploadFileError extends Action{
    type: typeof UPLOAD_FILE_FAILURE;
    message: string;
}
export interface PreviewFileRequest extends Action{
    type: typeof PREVIEW_FILE_REQUEST;
}
export interface PreviewFileSuccess extends Action{
    type: typeof PREVIEW_FILE_SUCCESS;
    message: string;
    previewUrls: Array<string>
}
export interface PreviewFileError extends Action{
    type: typeof PREVIEW_FILE_FAILURE;
    message: string;
}
export interface DeleteFileRequest extends Action{
    type: typeof DELETE_FILE_REQUEST;
}

export interface DeleteFileSuccess extends Action{
    type: typeof DELETE_FILE_SUCCESS;
    message: string;
    file: string;
}
export interface DeleteFileError extends Action{
    type: typeof DELETE_FILE_FAILURE;
    message: string;
}
export interface StatusRequest extends Action{
    type: typeof STATUS_REQUEST;
}
export interface StatusSuccess extends Action{
    type: typeof STATUS_SUCCESS;
    message: string;
}
export interface StatusError extends Action{
    type: typeof STATUS_FAILURE;
    message: string;
}