import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {useSnackbar} from 'notistack';
import {Avatar, Box, ButtonBase, Hidden, ListItemIcon, ListItemText, Menu, MenuItem, Theme, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {logout} from 'store/actions';
import {makeStyles, useTheme,} from "@mui/styles"
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from "../../../../store/types";
import {getInitial} from "utils";
import useRouter from "../../../../utils/useRouter";
import {LogOut, User} from "react-feather";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32,
    //marginRight: theme.spacing(1)
  },
  username:{
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();
  const theme: Theme = useTheme();
  const color = theme.palette.text.primary
  const dispatch = useDispatch();
  const router = useRouter();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    user
  } = useSelector((state: ApplicationState) => state.account);

  const handleProfil = async () => {
    router.history.push('/app/profile');
  }

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar(t('unable_to_disconnect'), {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}>
          <Hidden smDown ><Typography className={classes.username}>Max Musternutzer</Typography></Hidden>
        <Avatar src={user?.avatar?.url} alt={`${user?.firstname || ''}  ${user?.lastname || ''}`} className={classes.avatar} />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        // getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon> <LogOut color={color} /> </ListItemIcon>
          <ListItemText>{t('topbar_profile_logout')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleProfil}>
          <ListItemIcon> <User color={color}/> </ListItemIcon>
          <ListItemText>{t('topbar_profile_profil')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
