import React from 'react';
import {Provider} from 'react-redux';
import {
    createStyles,
    jssPreset,
    makeStyles,
    StylesProvider,
} from "@mui/styles";
import {create} from 'jss';
// import rtl from 'jss-rtl';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import {SnackbarProvider} from 'notistack';
import store from 'store';
import Routes from 'routes';
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';

import 'translations';
// import { ScrollReset } from 'components';
import Auth from 'routes/Auth';
import config from "./config";
import MockedButton from "./components/MockedButton";
import {StyledEngineProvider} from "@mui/styled-engine-sc";
// import 'styles/index.scss';

const jss = create({plugins: [...jssPreset().plugins,]});


const App = () => {
    return (
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
            <StyledEngineProvider injectFirst>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <StylesProvider jss={jss}>
                            <React.Fragment>
                                <CssBaseline/>
                                <Auth>
                                    {/*<ScrollReset />*/}
                                    <Routes/>
                                    {/* {config.env === 'DEV' && <MockedButton/>} */}
                                </Auth>
                            </React.Fragment>
                        </StylesProvider>
                    </ThemeProvider>
                </Provider>
            </StyledEngineProvider>
        </SnackbarProvider>
    );
};

export default App;
