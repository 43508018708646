import {colors, PaletteOptions} from "@mui/material";

const white = 'rgb(216, 218, 222)';
const black = '#000000';

const theme: PaletteOptions =  {
  primary: {
    main: '#111318',
    contrastText: 'rgb(255, 255, 255)',
    light: 'rgba(236, 37, 37, 0.76)',
    A400: '#1F32D8',
  },
  secondary: {
    main: '#1159E4',
  },
  common: {
    white,
    black,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  info: {
    main: '#04297a',
  },
  warning:{
    main: "#FF9F43",
  },
  text: {
    primary: 'rgba(26, 26, 26, 1)',
    secondary: ' rgba(103, 110, 137, 1)',
    disabled: 'rgba(43, 44, 67, 1)',
  },
  background: {
    default: '#FFFFFF',
    paper: "#FFFFFF",
  },
  divider: "#FAFAFA"
};

export default theme