/* eslint-disable no-unused-vars */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Fab, Hidden, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { Logo } from 'components';
import Account from './Account';
import { Menu as MenuIcon, Star as StarIcon, Mail as MailIcon, Calendar as CalendarIcon, MessageSquare as MessageSquareIcon, CheckSquare as CheckSquareIcon, MessageSquare } from 'react-feather';
import { useTranslation } from "react-i18next";
import SecureLS from "secure-ls";
import theme from 'theme';

const localStorage = new SecureLS({ encodingType: 'aes' });


interface TopBarProps {
  onMobileNavOpen: any;
  className?: string;
}

const TopBar = (props: TopBarProps) => {
  const { onMobileNavOpen, className, ...rest } = props;
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  React.useEffect(() => {
    i18n.changeLanguage(localStorage.get('language') || 'fr');
  }, [i18n]);

  const handleClose = (lng: string | undefined) => {
    if (!!lng) {
      i18n.changeLanguage(lng.toLowerCase());
      localStorage.set('language', lng.toLowerCase());
    }
    setAnchorEl(null);
  };

  return (
    <AppBar color={'inherit'} className={className} elevation={0} {...rest}>
      <Toolbar style={{paddingLeft:10, paddingRight:20}}>
        <Hidden lgUp>
          <IconButton
            // className={styles.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        {/* <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden> */}
        <Hidden smDown>
          <Box >
            <IconButton>
              <CheckSquareIcon color="#1A1A1A"/>
            </IconButton>
            <IconButton>
              <MessageSquareIcon color="#1A1A1A"/>
            </IconButton>
            <IconButton>
              <MailIcon color="#1A1A1A"/>
            </IconButton>
            <IconButton>
              <CheckSquareIcon color="#1A1A1A"/>
            </IconButton>
            <IconButton>
              <CalendarIcon color="#1A1A1A"/>
            </IconButton>
            <IconButton>
              <StarIcon color={`${theme.palette.warning.main}`} />
            </IconButton>
          </Box>
        </Hidden>


        <Box ml={2} flexGrow={1} />
        {/* <Fab
            color={'primary'}
            size={'small'}
            onClick={handleClick}
        >{i18n.language}</Fab> */}
        <Box >
          <Account />
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose(undefined)}>
          {[
            { value: 'fr', title: 'Français' },
            { value: 'en', title: 'English' }
          ].map((i) => (
            <MenuItem onClick={() => handleClose(i.value)} key={i.value}>
              {i.title}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
