/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    ClassificationState,
    ClassificationAction,
    GET_ALL_CLASSIFICATION_REQUEST,
    GET_ALL_CLASSIFICATION_SUCCESS,
    GET_ALL_CLASSIFICATION_FAILURE,
    GET_CLASSIFICATION_REQUEST,
    GET_CLASSIFICATION_SUCCESS,
    GET_CLASSIFICATION_FAILURE,
    ADD_CLASSIFICATION_REQUEST,
    ADD_CLASSIFICATION_SUCCESS,
    ADD_CLASSIFICATION_FAILURE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    PREVIEW_FILE_REQUEST,
    PREVIEW_FILE_SUCCESS,
    PREVIEW_FILE_FAILURE,
    DELETE_FILE_REQUEST,
    STATUS_SUCCESS,
    STATUS_REQUEST,
    STATUS_FAILURE,
    DELETE_FILE_FAILURE,
    DELETE_FILE_SUCCESS,
} from 'store/types';


const initialState: ClassificationState = {
    file: "",
    loading: {
        get_all_classification_loading: false,
        get_all_classification_success: false,
        get_all_classification_failed: false,
        get_all_classification_failed_message: '',

        add_classification_loading: false,
        add_classification_success: false,
        add_classification_failed: false,
        add_classification_failed_message: '',

        get_classification_loading: false,
        get_classification_success: false,
        get_classification_failed: false,
        get_classification_failed_message: '',

        upload_file_loading: false,
        upload_file_success: false,
        upload_file_failed: false,
        upload_file_failed_message: '',

        preview_file_loading: false,
        preview_file_success: false,
        preview_file_failed: false,
        preview_file_failed_message: '',

        delete_file_loading: false,
        delete_file_success: false,
        delete_file_failed: false,
        delete_file_failed_message: '',

        status_loading: false,
        status_success: false,
        status_failed: false,
        status_failed_message: '',
    },
    classifications: [],
    previewUrls:[],
};

const reducer = (state = initialState, action: ClassificationAction) => {
    switch (action.type) {
        case GET_ALL_CLASSIFICATION_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.file = undefined;
                draft.loading.get_all_classification_loading = true;
                draft.loading.get_all_classification_failed = false;
                draft.loading.get_all_classification_failed_message = '';
            });
        }
        case GET_ALL_CLASSIFICATION_SUCCESS: {
            const { classifications } = action;
            
            return produce(state, (draft) => {
                draft.classifications = classifications;
                draft.loading.get_all_classification_loading = false;
                draft.loading.get_all_classification_failed = false;
                draft.loading.get_all_classification_success = true;
                draft.loading.get_all_classification_failed_message = '';
            });
        }

        case GET_ALL_CLASSIFICATION_FAILURE: {
            const { message } = action;

            return produce(state, (draft) => {
                draft.loading.get_all_classification_loading = false;
                draft.loading.get_all_classification_failed = true;
                draft.loading.get_all_classification_failed_message = message;
            });
        }
        //Add Classification
        case ADD_CLASSIFICATION_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.loading.add_classification_loading = true;
                draft.loading.add_classification_failed = false;
                draft.loading.add_classification_failed_message = '';
            });
        }
        case ADD_CLASSIFICATION_SUCCESS: {
            return produce(state, (draft) => {
                draft.loading.add_classification_failed = false;
                draft.loading.add_classification_success = true;
                draft.loading.add_classification_failed_message = '';
            });
        }

        case ADD_CLASSIFICATION_FAILURE: {
            const { message } = action;

            return produce(state, (draft) => {
                draft.loading.add_classification_loading = false;
                draft.loading.add_classification_failed = true;
                draft.loading.add_classification_failed_message = message;
            });
        }


        //Get Classification
        case GET_CLASSIFICATION_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.loading.get_classification_loading = true;
                draft.loading.get_classification_failed = false;
                draft.loading.get_classification_failed_message = '';
            });
        }
        case GET_CLASSIFICATION_SUCCESS: {
            
            return produce(state, (draft) => {
                draft.loading.get_all_classification_failed = false;
                draft.loading.get_classification_success = true;
                draft.loading.get_all_classification_failed_message = '';
            });
        }

        case GET_CLASSIFICATION_FAILURE: {
            const { message } = action;

            return produce(state, (draft) => {
                draft.loading.get_classification_loading = false;
                draft.loading.get_classification_failed = true;
                draft.loading.get_classification_failed_message = message;
            });
        }

        //Upload files
        case UPLOAD_FILE_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.file = undefined;
                draft.loading.upload_file_loading = true;
                draft.loading.upload_file_failed = false;
                draft.loading.upload_file_failed_message = '';
            });
        }
        case UPLOAD_FILE_SUCCESS: {
            const { message } = action;
            
            return produce(state, (draft) => {
                draft.loading.upload_file_loading = false;
                draft.loading.upload_file_failed = false;
                draft.loading.upload_file_success = true;
                draft.loading.upload_file_failed_message = '';
            });
        }

        case UPLOAD_FILE_FAILURE: {
            const { message } = action;

            return produce(state, (draft) => {
                draft.loading.upload_file_loading = false;
                draft.loading.upload_file_failed = true;
                draft.loading.upload_file_failed_message = message;
            });
        }

        //Preview files
        case PREVIEW_FILE_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.loading.preview_file_loading = true;
                draft.loading.preview_file_failed = false;
                draft.loading.preview_file_failed_message = '';
            });
        }
        case PREVIEW_FILE_SUCCESS: {
            const  {previewUrls} = action;
            return produce(state, (draft) => {
                draft.previewUrls = previewUrls;
                draft.loading.preview_file_loading = false;
                draft.loading.preview_file_failed = false;
                draft.loading.preview_file_success = true;
                draft.loading.preview_file_failed_message = '';
            });
        }

        case PREVIEW_FILE_FAILURE: {
            const { message } = action;

            return produce(state, (draft) => {
                draft.loading.preview_file_loading = false;
                draft.loading.preview_file_failed = true;
                draft.loading.preview_file_failed_message = message;
            });
        }

        //Delete files
        case DELETE_FILE_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.file = undefined;
                draft.loading.delete_file_loading = true;
                draft.loading.delete_file_failed = false;
                draft.loading.delete_file_failed_message = '';
            });
        }
        case DELETE_FILE_SUCCESS: {
            const { file } = action;
            
            return produce(state, (draft) => {
                //Todo remove file from list
                draft.loading.delete_file_loading = false;
                draft.loading.delete_file_failed = false;
                draft.loading.delete_file_success = true;
                draft.loading.delete_file_failed_message = '';
            });
        }

        case DELETE_FILE_FAILURE: {
            const { message } = action;

            return produce(state, (draft) => {
                draft.loading.delete_file_loading = false;
                draft.loading.delete_file_failed = true;
                draft.loading.delete_file_failed_message = message;
            });
        }

    
        //Status classification
        case STATUS_REQUEST: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.loading.status_loading = true;
                draft.loading.status_failed = false;
                draft.loading.status_failed_message = '';
            });
        }
        case STATUS_SUCCESS: {
            
            return produce(state, (draft) => {
                draft.loading.status_loading = false;
                draft.loading.status_failed = false;
                draft.loading.status_success = true;
                draft.loading.status_failed_message = '';
            });
        }

        case STATUS_FAILURE: {
            const { message } = action;

            return produce(state, (draft) => {
                draft.loading.status_loading = false;
                draft.loading.status_failed = true;
                draft.loading.status_failed_message = message;
            });
        }
        default: {
            return state;
        }
    }
};

export default reducer;
