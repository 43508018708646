import React from 'react';
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    background: "linear-gradient(90deg, rgba(249, 30, 0, 0.76) -0.01%, rgba(234, 9, 36, 0.76) 100%, rgba(234, 9, 36, 0.76) 100%, rgba(234, 9, 36, 0.76) 100%)",
    borderRadius: "0px"
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export interface ButtonPrimaryProps extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

export default function ButtonPrimary(props: ButtonPrimaryProps) {
  const classes = useStyles();
  const { loading = false, disabled = false } = props;
  return (
    <Button className={clsx(props.className, classes.wrapper)} disabled={disabled || loading} {...props} >
      {props.children}
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
}
