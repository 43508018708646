import { Action } from 'redux';

export interface User {
  firstname: string;
  lastname?: string;
  email: string;
  role: string;
  phonenumber: string;
  is_password_changed?: boolean;
  public_id: string;
  avatar?: {
    url: string
  }
}



export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOGOUT = 'LOGOUT';

export interface LoadingAccountState {
  login_loading: boolean;
  login_failed: boolean;
  login_failed_message: string;

  update_profile_loading: boolean;
  update_profile_failed: boolean;
  update_profile_success: boolean;
  update_profile_failed_message: string;

  signup_loading: boolean;
  signup_success: boolean;
  signup_failed: boolean;
  signup_failed_message: string;

  update_password_loading: boolean;
  update_password_success: boolean;
  update_password_failed: boolean;
  update_password_failed_message: string;
}

export interface AccountState {
  loading: LoadingAccountState;
  token?: string;
  user?: User;
}

export interface LoginRequest extends Action {
  type: typeof LOGIN_REQUEST;
}

export interface LoginSuccess extends Action {
  type: typeof LOGIN_SUCCESS;
  token: string;
  user: User;
}

export interface LoginError extends Action {
  type: typeof LOGIN_FAILURE;
  message: string;
}

export interface SignUpRequest extends Action {
  type: typeof SIGNUP_REQUEST;
}

export interface SignUpSuccess extends Action {
  type: typeof SIGNUP_SUCCESS;
  message: string;
}

export interface SignUpError extends Action {
  type: typeof SIGNUP_FAILURE;
  message: string;
}

export interface UpdatePasswordRequest extends Action {
  type: typeof UPDATE_PASSWORD_REQUEST;
}
export interface UpdatePasswordReset extends Action {
  type: typeof UPDATE_PASSWORD_RESET;
}

export interface UpdatePasswordSuccess extends Action {
  type: typeof UPDATE_PASSWORD_SUCCESS;
  message: string;
}

export interface UpdatePasswordError extends Action {
  type: typeof UPDATE_PASSWORD_FAILURE;
  message: string;
}

export interface Logout extends Action {
  type: typeof LOGOUT;
}
