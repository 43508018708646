import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {SplashScreen} from 'components';
import {setTranslationFunction} from 'store/actions';
import {useTranslation} from 'react-i18next';
import {createStyles, makeStyles} from "@mui/styles";


const useStyles = makeStyles(() =>
    createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        a: {
          textDecoration: 'none!important'
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%'
        },
        '#root': {
          height: '100%',
          width: '100%'
        }
      }
    })
);
function Auth(props: any) {
  const { children } = props;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  useStyles();

  useEffect(() => {
    dispatch(setTranslationFunction(t));
  }, [dispatch, t]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

export default Auth;
