import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {useLocation, matchPath} from "react-router";
import clsx from 'clsx';
import { Box, Button, Collapse, ListItem, Theme, ListItemButton } from '@mui/material';
import { makeStyles } from "@mui/styles"

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from "react-feather";

interface NavItemProps {
    children?: React.ReactNode;
    className?: string;
    depth: number;
    href?: string;
    icon?: any;
    smallSize?: boolean;
    info?: any;
    open?: boolean;
    title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        display: 'block',
        // paddingTop: 0,
        // paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        boxShadow: 'none !important',
        fontFamily: "Montserrat !important",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "17px",
        // paddingTop: 0,
        // paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        // padding: '10px 32px',
        justifyContent: 'flex-start',
        // textTransform: 'none',
        // letterSpacing: 0,
        width: '100%'
    },
    buttonLeaf: {
        justifyContent: 'flex-start',
        width: 'calc(100% - 12px)',
        margin: '0px 0px',
        display: "flex",
        alignItems: "center",
        color: "#101010",
        '&:hover': {
            color: "white",
            background: "linear-gradient(90deg, rgba(228, 26, 58, 0.76) -0.01%, rgba(236, 37, 37, 0.76) 99.88%)",
            '& $title': {
                fontWeight: "bold  !important",
            },
            '& $icon': {
                color: "white",
            }
        },
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium
            }
        }
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        marginRight: ({ smallSize }: any) => smallSize ? 0 : theme.spacing(1),
    },
    titleHeader: {
        boxShadow: 'none !important',
        fontFamily: "Montserrat !important",
        fontStyle: "normal",
        fontWeight: "bold  !important",
        fontSize: 14,
        lineHeight: "17px",
        color: "#1A1A1A",
        marginRight: 'auto'
    },
    title: {
        boxShadow: 'none !important',
        fontFamily: "Montserrat !important",
        fontStyle: "normal",
        fontWeight: "normal  !important",
        fontSize: 14,
        lineHeight: "17px",
        display: ({ smallSize }: any) => smallSize ? 'none' : 'block',
        marginRight: 'auto'
    },
    active: {
        background: "linear-gradient(90deg, rgba(228, 26, 58, 0.76) -0.01%, rgba(236, 37, 37, 0.76) 99.88%)",
        color: "white",
        '& $title': {
            fontWeight: "bold  !important",
        },
        '& $icon': {
            color: "white",
        }
    },
    info: {}
}));

function NavItem(props: NavItemProps) {
    const {
        title,
        href,
        depth,
        children,
        icon: Icon,
        className,
        open: openProp = false,
        smallSize = false,
        info: Info,
        ...rest
    } = props;

    const location = useLocation();

    const classes = useStyles({ smallSize });
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleListItemClick = (event: any, index: number) => {
        console.log("current index", index);
        console.log("listening event : ", event);

        setSelectedIndex(index);
    };

    const handleToggle = (event: any) => {
        setOpen((prevOpen) => !prevOpen);
        handleListItemClick(event, props.depth);
    };

    let paddingLeft = 18;


    const style = { paddingLeft };

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}>
                <Button /*onClick={(event) => handleToggle(event)}*/
                    style={style}
                >
                    {Icon && <Icon width={19} className={classes.icon} />}
                    <div className={classes.titleHeader}>{t(title)}</div>
                    {/*open ? (
                        <ChevronUp />
                    ) : (
                        <ChevronDown />
                    )*/}
                </Button>
                <Collapse in={open}>
                    <Box sx={{  }}>
                        {children}
                    </Box>
                </Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}>
            {
                //@ts-ignore
                <ListItemButton
                    activeClassName={matchPath(location.pathname, href ? href : "") ? classes.active : ""}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    style={style}
                    to={href}>

                    {Icon ? (
                        <Icon width={19} className={classes.icon} />
                    ) : (
                        <div />
                        // <ReactSVG
                        //   src={Slack}
                        //   style={{ height: 20, width: 20, marginTop: 8 }}
                        //   className={classes.icon}
                        // />
                    )}
                    <div className={classes.title}>{t(title)}</div>
                    {Info && <Info className={classes.info} />}
                </ListItemButton>
            }
        </ListItem>
    );
}

export default NavItem;
